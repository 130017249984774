// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   kicker -> text
//   headline -> text / Überschrift
//   subheadline -> text
//   download_items -> bloks / Downloads
// end of automatic

import React from "react"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import BlockWrapper from "../technical/BlockWrapper"

import {
  Document32,
  Image32,
  DocumentAudio32,
  DocumentPdf32,
  DocumentVideo32,
  Table32,
  DocumentWordProcessor32,
  Archive32,
} from "@carbon/icons-react"

const DownloadsBlock = ({ block }) => {
  let fileExtensions = {
    jpg: <Image32 />,
    jpeg: <Image32 />,
    png: <Image32 />,
    mp3: <DocumentAudio32 />,
    mp4: <DocumentVideo32 />,
    pdf: <DocumentPdf32 className="w-full h-full" />,
    xlsx: <Table32 />,
    xlsm: <Table32 />,
    doc: <DocumentWordProcessor32 />,
    docx: <DocumentWordProcessor32 />,
    docm: <DocumentWordProcessor32 />,
    zip: <Archive32 />,
    rar: <Archive32 />,
    file: <Document32 />,
  }

  const getIconCarbon = (file) => {
    if (file) {
      let fileType = file.split(".")
      fileType = fileType[fileType.length - 1]
      if (fileExtensions[fileType]) {
        return fileExtensions[fileType]
      } else {
        return fileExtensions["file"]
      }
    }
  }

  const getFileName = (file) => {
    if (file) {
      let fileName = file.split("/")
      fileName = fileName[fileName.length - 1]
      return fileName
    }
  }

  const DownloadItem = ({ item }) => {
    return (
      <Link className="flex flex-row group" link={item.file.filename}>
        <span
          className={`block flex-shrink-0 mt-1 mr-4 h-8 w-8 text-xl font-bold transition-colors duration-300 ${
            block.block_bg?.indexOf("bg-primary") !== -1
              ? "group-hover:text-black"
              : "group-hover:text-primary-hover"
          }`}
        >
          {getIconCarbon(item.file.filename)}
        </span>
        <span>
          <span
            className={`block text-xl font-bold transition-colors duration-300 ${
              block.block_bg?.indexOf("bg-primary") !== -1
                ? "group-hover:text-black"
                : "text-primary group-hover:text-primary-hover"
            }`}
          >
            {item.title?.length > 0
              ? item.title
              : getFileName(item.file.filename)}
          </span>
          {item.description?.length > 0 && (
            <span className="inline-block prose max-w-none">
              {item.description}
            </span>
          )}
        </span>
      </Link>
    )
  }

  const DownloadButtonItem = ({ item }) => {
    return (
      <div className="mb-4">
        {item.description?.length > 0 && (
          <p className="block mb-4 prose max-w-none">
            {item.title?.length > 0 && <b>{item.title}: </b>}
            {item.description}
          </p>
        )}
        <Link
          className={`btn ${
            block.block_bg?.indexOf("bg-primary") !== -1
              ? "bg-white border border-white text-primary hover:bg-primary hover:text-white"
              : "bg-primary border border-primary text-white hover:bg-white hover:text-primary"
          }`}
          link={item.file.filename}
        >
          <span className="inline-block h-6 w-auto mr-4 -mb-1.5 font-bold">
            {getIconCarbon(item.file.filename)}
          </span>
          <span className="">
            {item.title?.length > 0
              ? item.title
              : getFileName(item.file.filename)}
          </span>
        </Link>
      </div>
    )
  }

  return (
    <BlockWrapper block={block} blockWidth="container" showHeadline="true">
      <div
        className={`flex flex-row flex-wrap -mx-grid ${
          block.headline?.length > 0 ? "pt-8" : ""
        }`}
      >
        {block.download_items?.length > 0 &&
          block.download_items.map((item, index) => (
            <div className="mb-4 md:w-1/2 px-grid" key={index}>
              {block.button_design ? (
                <DownloadButtonItem item={item} />
              ) : (
                <DownloadItem item={item} />
              )}
            </div>
          ))}
      </div>
    </BlockWrapper>
  )
}

export default DownloadsBlock
